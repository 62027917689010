<script>
import moment from 'moment';

export default {
  name: 'Detail',
  props: {
    event: { type: Object },
    recurrence: { type: Object }
  },
  data() {
    return {
      currentEvent: {},
      currentRecurrence: {}
    }
  },
  created() {
    this.currentEvent = this.event
    this.currentRecurrence = this.recurrence
  },
  methods: {
    formatDateTime(date) {
      return date ? moment(date).format("DD/MM/yyyy HH:mm") : '-';
    }
  }
}
</script>

<template>
<div class="eventDetail">
  <p v-if="currentEvent.extendedProps && currentEvent.extendedProps.pacients">Nome do Paciente: <strong>{{ currentEvent.extendedProps.pacients.nome }}</strong></p>
  <p v-if="currentEvent.extendedProps && currentEvent.extendedProps.treatment">Tratamentos: <b-badge variant="info" class="ml-1">{{ currentEvent.extendedProps.treatment.name }}</b-badge><b-badge variant="primary" class="ml-1">{{ currentEvent.extendedProps.approach.name }}</b-badge></p>
  <p v-if="currentEvent.extendedProps && currentEvent.extendedProps.agreement">Convênio: <strong>{{ currentEvent.extendedProps.agreement.name }}</strong></p>
  <p>Início do atendimento: <strong>{{ formatDateTime(currentEvent.start) }}</strong></p>
  <p>Fim do atendimento: <strong>{{ formatDateTime(currentEvent.end) }}</strong></p>
  <blockquote  v-if="currentEvent.extendedProps" class="blockquote font-size-16 mb-0">
    <p>Parecer do Profissional: <strong>{{ (currentRecurrence) ? currentRecurrence.desc : '' }}</strong></p>
  </blockquote>
</div>
</template>