<script>
import appConfig from "@/app.config";
import HorizontalNav from "@/components/horizontal-topbar";
import { layoutComputed } from "@/state/helpers";
import Calendar from "./responsible_calendar";

export default {
    page: {
      title: 'Agenda de clientes',
      meta: [{
        name: 'description',
        content: appConfig.description
      }],
    },
    components: {
        HorizontalNav,
        Calendar
    },
    computed: {
        ...layoutComputed,
    },
    created() {
        document.body.setAttribute("data-layout", "horizontal");
        document.body.setAttribute("data-topbar", "dark");
        document.body.removeAttribute("data-sidebar", "dark");
        document.body.removeAttribute("data-layout-size", "boxed");
    },
    mounted() {
        document.body.classList.remove("auth-body-bg");
    },
}
</script>
<template>
    <div>
        <!-- Begin page -->
        <div id="layout-wrapper">
            <HorizontalNav :type="topbar" :width="layoutWidth"/>
            <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== -->
            <div class="main-content">
                <div class="page-content">
                    <div class="container-fluid">
                        <h4 class="header-title">Agenda de Clientes</h4>
                        <!-- container-fluid -->
                        <Calendar />
                    </div>
                    <!-- End Page-content -->
                </div>
                <!-- end main content-->
            </div>
            <!-- END layout-wrapper -->
        </div>
    </div>
</template>