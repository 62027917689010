<script>
import Vue from 'vue';
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from '@fullcalendar/rrule';
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { INITIAL_EVENTS, createEventId } from "../calendario/event-utils";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import moment from "moment";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { CalendarController } from "../../../controller/CalendarController";
import Detail from "./detail";

export default {
  props: {
    vet_responsible: { type: Array },
    vet_specialtys: { type: Array },
  },
  components: {
    FullCalendar,
    Multiselect,
  },
  data() {
    return {
      currentEmpresa: {},
      currentUser: {},
      vtitle:null,
      input_title_bol:false,
      specialtys: [],
      agenda: {},
      objeto_geral: null,
      modal_tittle_editar: null,
      profissionais: [],
      professional: null,
      select_prof: null,
      responsaveis: [],
      select_paciente: null,
      pacientes: [],
      pacientsSelect: null,
      modal_edit_del: false,
      vet_registros: [],
      vet_env_back: [],
      abr_div: false,
      tp_consulta: null,
      resp: null,
      select_inf: null,
      titulo: null,
      nome: null,
      showModal: false,
      calendarOptions: {
        plugins: [
          rrulePlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          bootstrapPlugin,
          listPlugin,
        ],
        locales: [ptBrLocale],
        // timeZone: 'America/Sao_Paulo',
        headerToolbar: {
          left: "prevYear,prev,next,nextYear today",
          center: "title",
          // mostra os botões de mês, semanas dias e listagem dia e mês
          right: "dayGridMonth,timeGridWeek,timeGridDay, listWeek,listDay",
        },
        initialView: "dayGridMonth",
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        initialDate: new Date(),
        eventColor: null, // Azul
        eventTextColor: "#FFFFFF", // Branco
        droppable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDrop: this.handleEventDrop,
        eventResize: this.handleEventResize,
        navLinks: true, // can click day/week names to navigate views
      },
      currentEvents: [],
      toogleIntructions: false,
    };
  },
  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    this.specialtys     = this.vet_specialtys;
  },
  mounted() {
    this.fetchList();
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append("empresa_id", this.currentEmpresa.id);

      if (this.pacientsSelect && this.pacientsSelect.length) {
        this.pacientsSelect.forEach((p) => {
          params.append("pacients[]", p.id);  // repare no pacients[]
        });
      }

      return params;
    },
    dateAgrement() {
      const date = new Date();
      date.setMinutes(date.getMinutes() + 40);
      return date;
    },
  },
  methods: {
    async fetchList() {
      let listObjects = await CalendarController.getCustomerCalendar(this.axiosParams);
      this.listAgendas   = CalendarController.eventsResource(listObjects.agendas);
      this.vet_registros = listObjects.agendas;
      this.pacientes     = listObjects.pacients;
      this.responsaveis  = listObjects.responsibles;
      this.gerando_vetor_calentario();
    },
    alter_title(event){
      console.log(event);
      this.input_title_bol = (this.vtitle);
    },
    seleciona_specialty(event) {
      console.log(event);
    },
    seleciona_paciente(objeto) {
      let pacient = this.pacientes.find((p) => p.id == objeto.target.value);
      this.agenda.titulo = pacient.nome;
    },
    seleciona_prof(objeto) {
      let prof = this.profissionais.find((p) => p.id == objeto.target.value);
      this.agenda.titulo = `${this.agenda.titulo} | ${prof.name}`;
    },
    fechar_modal_edit() {
      this.modal_edit_del = false;
    },
    // evento para redimensionar o agendas ja existentes
    handleEventResize(eventResizeInfo) {
      // Lógica para atualizar a duração de um evento ao redimensioná-lo
      eventResizeInfo.revert();
    },
    handleDateClick(arg) {
      console.log(arg);
    },
    handleEventDrop(info) {
      info.revert();
    },
    // evento para editar e exluir
    handleEventClick(clickInfo) {
      // pesquisa o evento na lista de calendários por id
      this.objeto_geral = this.listAgendas.find((e) => e.id == clickInfo.event.id);
      this.viewEvent(clickInfo.event);
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    handleDateSelect(selectInfo) {
      console.log(selectInfo);
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    abrir_modal(selectInfo) {
      this.showModal = true;
      this.select_inf = selectInfo;
    },
    fechar_modal2() {
      this.showModal = false;
    },
    convertToFullCalendarDate(datetimeLocal) {
      // O formato recebido é "YYYY-MM-DDTHH:MM:SS", então apenas criar o objeto Date
      return new Date(datetimeLocal);
    },
    convertToDateTimeLocal(date) {
      // Converte o objeto Date para o formato "YYYY-MM-DDTHH:MM:SS"
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adiciona o zero à esquerda se necessário
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      // Formata como 'YYYY-MM-DDTHH:MM:SS'
      let hora_tot = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return hora_tot;
    },
    fechar_modal() {
      let title = this.agenda.titulo;
      let calendarApi = this.select_inf.view.calendar;
      calendarApi.unselect(); // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: this.convertToFullCalendarDate(this.agenda.dt_ini),
          end: this.convertToFullCalendarDate(this.agenda.dt_fin),
          extendedProps: {
            description: this.agenda.desc,
            pacient_id: this.agenda.pacient_id,
            professional_id: this.agenda.professional_id,
            specialty_id: this.agenda.specialty,
            // Adicionando empresa_id em extendedProps
          },
        });
      }
      this.vet_env_back = [];
      this.currentEvents.forEach((e) => {
        let objeto = {
          title: e.title,
          start: this.isCustomDateFormat(e.start)
            ? this.convertToDateTimeLocal(e.start)
            : e.start,
          end: this.isCustomDateFormat(e.end)
            ? this.convertToDateTimeLocal(e.end)
            : e.end,
          desc: e.extendedProps.description,
          pacient_id: e.extendedProps.pacient_id,
          professional_id: e.extendedProps.professional_id,
          specialty_id: e.extendedProps.specialty_id,
        };
        this.vet_env_back.push({ ...objeto });
      });
    },
    isCustomDateFormat(dateString) {
      // Verifica se a string corresponde ao formato: Thu Sep 19 2024 00:00:00 GMT-0300 (Horário Padrão de Brasília)
      const regex =
        /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \([A-Za-zÀ-ÿ\s]+\)$/;
      const isMatch = regex.test(dateString);

      return isMatch;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    gerando_vetor_calentario() {
      let vet = [...this.vet_registros];
      let vet_aux = [];

      vet.forEach((e) => {
        console.log(e);
        let objeto = {
          id: e.id,
          title: ((e.professional) ? e.professional.name : '') +' - '+ ((e.treatment) ? e.treatment.name : ''),
          start: moment(e.start).format("yyyy-MM-DD"),
          end: (e.end) ? moment(e.end).format("yyyy-MM-DD") : null,
          startTime: e.start_time,
          endTime: e.end_time,
          daysOfWeek: [e.day_of_week],
          startRecur: e.start_recur,
          endRecur: e.end_recur,
          color: (e.professional.color) ? e.professional.color : "#0000ff", // Cor de fundo azul
          textColor: (e.professional.color) ? this.lumine(e.professional.color) : '#ffffff', // Texto branco
          extendedProps: {
            empresa_id: e.empresa_id,
            professional: e.professional,
            pacients: e.pacient,
            pacient_id: e.pacient_id,
            professional_id: e.professional_id,
            specialty_id: e.specialty_id,
            description: e.desc,
            atendimento_id: e.atendimento_id,
            recurrences: e.recurrences,
            atendimento: e.atendimento,
            treatment: (e.treatment) ? e.treatment : null,
            approach: e.approach,
            agreement: e.agreement
          },
          display: "block",
        };

        // Adicionando a rrule para o evento
        if (e.recurrence.toUpperCase() == 'MONTHLY')
        {
          objeto.rrule = {
            freq: e.recurrence,
            interval: 1,
            dtstart:  e.start,
            until: (e.end_recur) ? moment(e.end_recur).format('YYYY-MM-DD') : null, // data final da recorrência
            bymonthday: parseInt(moment(e.start).format('DD')), // específico para o dia 15
          }
        } // end recurrency monthly

        if (e.recurrence.toUpperCase() == 'YEARLY')
        {
          objeto.rrule = {
            freq: 'YEARLY',
            interval: 1,
            dtstart: e.start,
            until: (e.end_recur) ? moment(e.end_recur).format('YYYY-MM-DD') : null, // data final da recorrência
            bymonth: parseInt(moment(e.start).format('MM')), // específico para o mês de março
            bymonthday: parseInt(moment(e.start).format('DD')), // específico para o dia 15
          }
        } // end recurrency year

        if (e.professionals && e.professionals.length > 0)
        {
          objeto.professionals = e.professionals;
          objeto.color = '#000000';
          objeto.textColor = '#FFFFFF';
          objeto.title = "REUNIÃO - " + e.pacient.nome;
          objeto.meet = true;
        }

        vet_aux.push({ ...objeto });
      }); // end foreach

      this.calendarOptions.events = [...vet_aux];
      this.abr_div = false;
      setTimeout(() => {
        this.abr_div = true;
        return this.calendarOptions.events;
      }, 100);
    },
    hideIntructions() {
      this.toogleIntructions = !this.toogleIntructions;
    },
    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Atendimento gravado!",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    // Abrir evento clicado
    viewEvent(event) {
      let eventFormatted   = this.objeto_geral
      eventFormatted.start = event.start
      eventFormatted.end   = event.end
      eventFormatted.extendedProps = event.extendedProps
      const recurrence     = event.extendedProps.recurrences.filter((item) => {
        return moment(item.start).format('YYYY-MM-DD') == moment(event.start).format('YYYY-MM-DD')
      })[0]
      eventFormatted.desc  = (recurrence) ? recurrence.desc : ''
      // Cria um div para montar o componente
      const wrapper = document.createElement('div');
      // Monta o componente Detail
      const detailComponent = new (Vue.extend(Detail))({
        propsData: {
          event: eventFormatted,
          recurrence: recurrence
        }
      }).$mount();
      // Adiciona o componente ao div
      wrapper.appendChild(detailComponent.$el);

      Swal.fire({
          title: event.title,
          html: wrapper,
          cancelButtonText: "<i class='ri-close-circle-line'></i> Fechar",
          cancelButtonColor: "#ff0a36",
          showCancelButton: true,
          showConfirmButton: false,
      });
    },
    lumine(rgb) {
      let color = '#FFFFFF';
      var nib = rgb.split(''); 
      var r = parseInt(nib[1]+nib[2],16);
      var g = parseInt(nib[3]+nib[4],16);
      var b = parseInt(nib[5]+nib[6],16);
      var lumin = ( r * 299 + g * 587 + b * 114) / 1000;
      
      if (lumin > 128) {
        color = '#000000';
      }

      return color;
    },
      // Return the calculated text color
  },
};

</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-button
              v-b-toggle="'collapse-instructions'"
              class="m-1"
              variant="warning"
              >Instruções</b-button
            >
            <b-button
              v-b-toggle="'collapse-filters'"
              class="m-1"
              variant="primary"
              >Filtros</b-button
            >
            <b-collapse id="collapse-instructions" class="mt-1">
              <b-card>
                <div class="demo-app-sidebar-section">
                  <ul>
                    <li>
                      Selecione datas e você será solicitado a criar um novo
                      evento
                    </li>
                    <li>Arraste, solte e redimensione eventos</li>
                    <li>Clique em um evento para excluí-lo</li>
                  </ul>
                </div>
                <div class="demo-app-sidebar-section">
                  <label>
                    <input
                      type="checkbox"
                      :checked="calendarOptions.weekends"
                      @change="handleWeekendsToggle"
                    />
                    Adicionar Finais de Semana
                  </label>
                </div>
              </b-card>
            </b-collapse>

            <b-collapse id="collapse-filters" class="mt-1">
              <b-card>
                <div class="row">
                  <div class="col-md-6">
                    <label for="pacientes">Por Pacientes:</label>
                    <Multiselect
                      :options="pacientes"
                      v-model="pacientsSelect"
                      :multiple="true"
                      selectLabel="Pressione ENTER para selecionar"
                      deselectLabel="Pressione ENTER para remover"
                      placeholder="Selecione um Paciente"
                      track-by="id"
                      label="nome"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-button @click="fetchList()" variant="primary"
                      >Filtrar</b-button
                    >
                  </div>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="abr_div">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar
                class="demo-app-calendar"
                :options="calendarOptions"
                :locale="'pt-br'"
              >
                <template v-slot:eventContent="arg">
                  <b>{{ arg.timeText }}</b>
                  <i>{{ arg.event.title }}</i>
                </template>
              </FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Detail v-if="false"/>
  </div>
</template>

<style>
.fc-event,
.fc-event-dot {
  background-color: #ffffff;
}
.editable-span {
  cursor: pointer;
  border-bottom: 1px dashed #ffffff;
  color: #007bff;
}
.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}
</style>